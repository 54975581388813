import styled from 'styled-components';

export const EditImageContainer = styled.div`
  position: relative;
  display: inline-flex;

  .icon-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border: 1px solid $primaryColor;
    border-radius: 50%;
    background: #ffffffd8;
    opacity: 1;
    position: absolute;
    right: 0;
    bottom: 0;
    cursor: pointer;

    &:hover {
      background: $primaryColor;

      path {
        fill: #ffffff;
      }
    }
  }
`;

export const EditedImage = styled.img`
  border-radius: $borderRadiusClassic $borderRadiusClassic $borderRadiusForImage
    $borderRadiusClassic;
`;
