//React
import React, {useState, useEffect, useMemo} from 'react';
import {Link, useLocation} from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChevronRight, faPlay} from '@fortawesome/free-solid-svg-icons';
import {Tooltip} from '@mui/material';

//Mocks
import {settingsData} from '../../../layout/mocks';

// Styles
import {TipsWrapper} from '../../../layout/styled';
import * as S from './styled';

export const Sidebar = ({data, onOpenSideBar, isOpenedSidebar}) => {
  const [activeTabSideBar, setActiveTabSideBar] = useState(null);
  const location = useLocation();
  const doubleUrl = location.pathname?.split('/').slice(0, 3).join('/');

  const pathName = location.pathname.split('/')[1];
  const conditionUrl = elem => elem.slug.split('/')[1] === pathName;
  const conditionUrlsSettings = elem => elem.slug === doubleUrl;
  const [activeItem, setActiveItem] = useState(null);
  const [openSubmenu, setOpenSubmenu] = useState(null);
  const [isPageSettings, setIsPageSettings] = useState(false);
  const [activeSidebar, setActiveSidebar] = useState(false);
  const [focused, setFocused] = useState(false);

  const toggleSideMenu = () => {
    onOpenSideBar(!isOpenedSidebar);
  };
  const queryParameters = new URLSearchParams(location.search);
  const tabOrderUrl = queryParameters.get('tab') || 1;
  const changeStateSidebar = state => {
    setActiveSidebar(state);
  };

  useMemo(() => {
    if (pathName === 'settings') {
      setIsPageSettings(true);
    } else {
      setIsPageSettings(false);
    }
  }, [pathName, isOpenedSidebar]);

  useEffect(() => {
    if (location.pathname === '/' || pathName === 'orders-hugg') {
      setActiveItem(1);
    } else {
      const firstActiveItem = data.find(
        item => conditionUrl(item) || item?.subItems?.find(conditionUrl),
      );
      setActiveItem(firstActiveItem?.id);
      setOpenSubmenu(firstActiveItem?.id);
    }
    const activeItemSidebar = settingsData?.submenu?.find(
      item =>
        conditionUrlsSettings(item) ||
        item?.subItems?.find(conditionUrlsSettings),
    );
    setActiveTabSideBar(activeItemSidebar?.id);
  }, [location.pathname]);

  const sidebarMenuItems = data.length
    ? data.map(item => {
        return (
          <S.SidebarElemList key={item.id}>
            <S.SidebarItem
              $active={activeItem === item?.id}
              $isOpenedSidebar={isOpenedSidebar}>
              <Link
                to={item.slug}
                onClick={() => {
                  setOpenSubmenu(item.id);
                  setActiveItem(item?.id);
                  onOpenSideBar(true);
                }}>
                <Tooltip
                  title={item.title}
                  arrow
                  placement={'right'}
                  disableHoverListener={
                    isOpenedSidebar && activeItem !== item?.id
                  }
                  disableFocusListener={
                    isOpenedSidebar && activeItem !== item?.id
                  }
                  disableTouchListener={
                    isOpenedSidebar && activeItem !== item?.id
                  }
                  disableInteractive={
                    isOpenedSidebar && activeItem !== item?.id
                  }>
                  <S.SideBarItemImg>{item.icon}</S.SideBarItemImg>
                </Tooltip>
                <S.SideBarItemTitle>{item.title}</S.SideBarItemTitle>
              </Link>
            </S.SidebarItem>
            {openSubmenu === item?.id && item?.subItems?.length ? (
              <S.SidebarSubMenu $isOpenedSidebar={isOpenedSidebar}>
                {item.subItems.map(subItem => (
                  <S.SubMenuItem
                    key={subItem.id}
                    $selected={
                      doubleUrl === subItem.slug ||
                      +tabOrderUrl === subItem.id
                    }>
                    <Link to={subItem.slug}>
                      <span>{subItem.title}</span>
                    </Link>
                  </S.SubMenuItem>
                ))}
              </S.SidebarSubMenu>
            ) : null}
          </S.SidebarElemList>
        );
      })
    : null;

  const settingsSideBar = (
    <>
      <S.SettingsPopupWrapper $isOpenedSidebar={isOpenedSidebar}>
        {/*<S.TitleSettingsSideBar>*/}
        {/*  {settingsData.titleSideBar}*/}
        {/*</S.TitleSettingsSideBar>*/}
        {settingsData?.submenu?.length
          ? settingsData?.submenu.map(item => (
              <React.Fragment key={item.id}>
                <S.SideBarSetting
                  key={item.id}
                  $numberTab={activeTabSideBar === item.id}
                  $isOpenedSidebar={isOpenedSidebar}>
                  <Link
                    to={item.slug}
                    onClick={() => {
                      setOpenSubmenu(item.id);
                      setActiveTabSideBar(item.id);
                      onOpenSideBar(true);
                    }}>
                    {isOpenedSidebar ? (
                      <S.SideBarSettingImg>{item.icon}</S.SideBarSettingImg>
                    ) : (
                      <Tooltip
                        title={item.title}
                        arrow
                        placement={'right'}
                        disableInteractive>
                        <S.SideBarSettingImg>{item.icon}</S.SideBarSettingImg>
                      </Tooltip>
                    )}
                    <S.SideBarSettingTitle $isOpenedSidebar={isOpenedSidebar}>
                      {item.title}
                    </S.SideBarSettingTitle>
                  </Link>
                </S.SideBarSetting>
                {!!item?.subItems?.length && activeTabSideBar === item?.id && (
                  <S.SettingsSubListWr>
                    <S.SidebarSubMenu $isOpenedSidebar={isOpenedSidebar}>
                      {(item?.subItems || []).map(subItem => (
                        <S.SubMenuItem
                          key={subItem?.id}
                          selected={conditionUrlsSettings(subItem)}>
                          <Link to={subItem.slug}>
                            <span>{subItem.title}</span>
                          </Link>
                        </S.SubMenuItem>
                      ))}
                    </S.SidebarSubMenu>
                  </S.SettingsSubListWr>
                )}
              </React.Fragment>
            ))
          : null}
      </S.SettingsPopupWrapper>
    </>
  );

  const settingButton = (
    <S.SidebarItem $isOpenedSidebar={isOpenedSidebar}>
      <Link
        to={settingsData.slug}
        onClick={() => {
          setOpenSubmenu(settingsData.id);
          onOpenSideBar(false);
        }}>
        <Tooltip
          title={settingsData?.title}
          arrow
          placement={'right'}
          followCursor={isOpenedSidebar}
          disableHoverListener={isOpenedSidebar}
          disableFocusListener={isOpenedSidebar}
          disableTouchListener={isOpenedSidebar}
          disableInteractive={isOpenedSidebar}>
          <S.SideBarItemImg>{settingsData.icon}</S.SideBarItemImg>
        </Tooltip>
        <S.SideBarItemTitle>{settingsData.title}</S.SideBarItemTitle>
      </Link>
    </S.SidebarItem>
  );

  const buttonSettings =
    // eslint-disable-next-line no-undef
    process.env.REACT_APP_APPSCHEME === 'tixxio' && !isPageSettings ? (
      <S.SettingBlock
        onClick={() => {
          setActiveTabSideBar(null);
        }}>
        {settingButton}
      </S.SettingBlock>
    ) : null;
  return (
    <>
      <S.SidebarWrapper
        $isOpen={isOpenedSidebar}
        $activeSidebar={focused}
        onMouseEnter={() => {
          changeStateSidebar(true);
        }}
        onMouseLeave={() => {
          changeStateSidebar(false);
        }}>
        <S.SidebarList className="sidebar-list">
          {isPageSettings ? settingsSideBar : sidebarMenuItems}
        </S.SidebarList>
        {buttonSettings}
        {/*{process.env.REACT_APP_APPSCHEME === 'huggs' ? null : (*/}
        {/*  <S.SidebarBtn className="sidebar-btn" onClick={onButtonClick}>*/}
        {/*    <FontAwesomeIcon icon={faChevronRight} />*/}
        {/*  </S.SidebarBtn>*/}
        {/*)}*/}
      </S.SidebarWrapper>
      <S.IconOpenMenu
        $isOpened={isOpenedSidebar}
        $activeSidebar={activeSidebar}
        onMouseEnter={() => {
          setActiveSidebar(true);
          setFocused(true);
        }}
        onMouseLeave={() => {
          setActiveSidebar(false);
          setFocused(false);
        }}
        onClick={() => {
          toggleSideMenu();
          setActiveSidebar(true);
        }}>
        <FontAwesomeIcon icon={faChevronRight} />
      </S.IconOpenMenu>
    </>
  );
};
