import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {addBuilderCases, asyncThunkHelper} from '../utils';
import {
  getPrintingProfiles,
  getPrintingProfile,
  createPrintingProfile,
  updatePrintingProfile,
  deletePrintingProfile,
  getPrintingProfileSettings,
  updatePrintingProfileSettings,
  createPrintingProfileSettings,
  clonePrintingProfile,
  getPrintingProcessors,
  getPrintingFolders,
} from '../../api/helpers/printing';

export const fetchPrintingFolders = createAsyncThunk(
  'fetchPrintingFolders',
  async (payload: any) =>
    await asyncThunkHelper(payload, payload?.type, {
      reset: () => ({data: null}),
      default: async () => await getPrintingFolders(payload?.data),
    }),
);

export const fetchPrintingProfiles = createAsyncThunk(
  'fetchPrintingProfiles',
  async (payload: any) =>
    await asyncThunkHelper(payload, payload?.type, {
      reset: () => ({data: null}),
      default: async () => await getPrintingProfiles(payload?.data),
    }),
);
export const fetchPrintingProfile = createAsyncThunk(
  'fetchPrintingProfile',
  async (payload: any) =>
    await asyncThunkHelper(payload, payload?.type, {
      reset: () => ({data: null}),
      default: async () => await getPrintingProfile(payload?.id),
      create: async () => await createPrintingProfile(payload?.data),
      update: async () =>
        await updatePrintingProfile(payload?.id, payload?.data),
      delete: async () => await deletePrintingProfile(payload?.id),
      clone: async () => await clonePrintingProfile(payload?.id, payload?.data),
    }),
);

export const fetchPrintingProfileSettings = createAsyncThunk(
  'fetchPrintingProfileSettings',
  async (payload: any) =>
    await asyncThunkHelper(payload, payload?.type, {
      reset: () => ({data: null}),
      create: async () =>
        await createPrintingProfileSettings(payload?.id, payload?.data),
      update: async () =>
        await updatePrintingProfileSettings(payload?.id, payload?.data),
      default: async () => await getPrintingProfileSettings(payload?.id),
    }),
);
export const fetchPrintingProcessors = createAsyncThunk(
  'fetchPrintingProcessors',
  async (payload: any) =>
    await asyncThunkHelper(payload, payload?.type, {
      reset: () => ({data: null}),
      default: async () => await getPrintingProcessors(payload?.data),
    }),
);


export const printingSlice = createSlice({
  name: 'printing',
  initialState: {
    profiles: {data: []},
    profile: {data: null},
    processors: {data: []},
    folders: {data: []},

  },
  reducers: {},
  extraReducers: (builder: any): any => {
    addBuilderCases(builder, fetchPrintingFolders, 'folders');
    addBuilderCases(builder, fetchPrintingProfiles, 'profiles');
    addBuilderCases(builder, fetchPrintingProfile, 'profile');
    addBuilderCases(builder, fetchPrintingProfileSettings, 'profileSettings');
    addBuilderCases(builder, fetchPrintingProcessors, 'processors');

  },
});
