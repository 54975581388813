import React from 'react';
import {useTranslation} from 'react-i18next';
import * as S from './styled';
import {ArrowBack} from '@mui/icons-material';
import {BtnProps, BackButtonProps} from './types';

const onNavigate = ({
  buttonDetails,
  e,
}: {
  buttonDetails: BtnProps;
  e: React.MouseEvent<HTMLElement>;
}) => {
  e.preventDefault();
  buttonDetails.link();
};

const Btn = ({buttonDetails}: {buttonDetails: BtnProps}): JSX.Element => {
  const {t} = useTranslation();
  return (
    <S.ButtonElement
      onClick={e => {
        onNavigate({e, buttonDetails});
      }}>
      <ArrowBack /> {t(buttonDetails.text)}
    </S.ButtonElement>
  );
};

export const BackButton = ({
  ButtonComponent = Btn,
  buttonDetails,
}: BackButtonProps): JSX.Element => {
  return <ButtonComponent buttonDetails={buttonDetails} />;
};
