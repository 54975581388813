import {configureStore} from '@reduxjs/toolkit';
import {authSlice} from './auth/authSlice';
import {clientSlice} from './client/clientSlice';
import {commonSlice} from './common/commonSlice';
import {integrationSlice} from './integration/integrationSlice';
import {reportingSlice} from './reporting/reportingSlice';
import {platformSlice} from './platform/platformSlice';
import {billingSlice} from './billing/billingSlice';
import {marketingSlice} from './marketing/marketingSlice';
import {productDesignerSlice} from './productDesigner/productDesignerSlice';
import {supportSlice} from './support';
import {shipmentSlice} from './shipment/shipmentSlice';
import {notificationSlice} from './notification';
import {printingSlice} from './printing';

export const store = configureStore({
  reducer: {
    auth: authSlice.reducer,
    client: clientSlice.reducer,
    common: commonSlice.reducer,
    integration: integrationSlice.reducer,
    reporting: reportingSlice.reducer,
    platform: platformSlice.reducer,
    billing: billingSlice.reducer,
    marketing: marketingSlice.reducer,
    productDesigner: productDesignerSlice.reducer,
    support: supportSlice.reducer,
    shipment: shipmentSlice.reducer,
    notification: notificationSlice.reducer,
    printing: printingSlice.reducer,
  },
});

// Infer the `RootState` and `AppDispatch` Types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
