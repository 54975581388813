import ApiV2 from '../ApiV2';

export const getProducts = async (payload: any): Promise<any> => {
  const response = await ApiV2.cp.marketing.products.get(payload);
  return {data: response?.data?.data, meta: response?.data?.meta};
};

export const getProduct = async (id: number): Promise<any> => {
  const response = await ApiV2.cp.marketing.products.get(id);
  return response?.data;
};

export const addProduct = async (data: object): Promise<any> => {
  const response = await ApiV2.cp.marketing.products.post(data);
  return response?.data;
};

export const updateProduct = async (id: number, data: object): Promise<any> => {
  const response = await ApiV2.cp.marketing.products.patch(id, data);
  return response?.data;
};

export const deleteProduct = async (id: number): Promise<any> => {
  const response = await ApiV2.cp.marketing.products.delete(id);
  return response?.data;
};

export const getProductCategories = async (payload: any): Promise<any> => {
  const response = await ApiV2.cp.marketing.categories.get(payload);
  return response?.data;
};
export const deleteProductCategory = async (id: number): Promise<any> => {
  const response = await ApiV2.cp.marketing.categories.delete(id);
  return response?.data;
};

export const updateProductCategory = async (
  id: number,
  payload: any,
): Promise<any> => {
  const response = await ApiV2.cp.marketing.categories.patch(id, payload);
  return response?.data;
};
export const getProductCategory = async (id: number): Promise<any> => {
  const response = await ApiV2.cp.marketing.categories.get(id);
  return response?.data;
};
export const uploadProductCategoryImages = async (
  id: number,
  data: object,
): Promise<any> => {
  const response = await ApiV2.cp.marketing.categories.images.post(id, data);
  return response?.data;
};

export const deleteProductCategoryImages = async (
  id: number,
  data: object,
): Promise<any> => {
  const response = await ApiV2.cp.marketing.categories.images.delete(id, data);
  return response?.data;
};
export const createProductCategory = async (
  id: number,
  payload: any,
): Promise<any> => {
  const response = await ApiV2.cp.marketing.categories.post(id, payload);
  return response?.data;
};

export const addCategoryToProduct = async (
  id: number,
  data: object,
): Promise<any> => {
  const response = await ApiV2.cp.marketing.categories.post(id, data);
  return response?.data;
};

export const getProductStatuses = async (payload: any): Promise<any> => {
  const response = await ApiV2.cp.marketing.productStatuses.get(payload);
  return response?.data;
};
export const removeCategoryFromProduct = async (
  id: number,
  data: object,
): Promise<any> => {
  const response = await ApiV2.cp.marketing.categories.delete(id, data);
  return response?.data;
};

export const createCategory = async (data: object): Promise<any> => {
  const response = await ApiV2.cp.marketing.categories.post(data);
  return response?.data?.data;
};

export const addCategoriesToProduct = async (
  id: number,
  data: object,
): Promise<any> => {
  const response = await ApiV2.cp.marketing.products.categories.post(id, data);
  return response?.data;
};

export const removeCategoriesFromProduct = async (
  id: number,
  data: object,
): Promise<any> => {
  const response = await ApiV2.cp.marketing.products.categories.delete(
    id,
    data,
  );
  return response?.data;
};

export const getProductOptions = async (payload: any): Promise<any> => {
  const response = await ApiV2.cp.marketing.options.get(payload);
  return response?.data;
};

export const getProductOption = async (id: number): Promise<any> => {
  const response = await ApiV2.cp.marketing.options.get(id);
  return response?.data;
};

export const createProductOption = async (data: object): Promise<any> => {
  const response = await ApiV2.cp.marketing.options.post(data);
  return response?.data;
};
export const updateProductOption = async (id: number | string, data: any) => {
  const response = await ApiV2.cp.marketing.options.patch(
    id,
    data,
  );
  return response?.data;
};
export const deleteProductOption = async (id: number | string) => {
  const response = await ApiV2.cp.marketing.options.delete(id);
  return response?.data;
};

export const addValueToProductOption = async (
  id: number,
  data: object,
): Promise<any> => {
  const response = await ApiV2.cp.marketing.options.values.post(id, data);
  return response?.data;
};
export const updateValueToProductOption = async (oid: number | string, id: number | string, data: any) => {
  const response = await ApiV2.cp.marketing.options.values.patch(
    {oid, id},
    data,
  );
  return response?.data;
};
export const deleteValueToProductOption = async (oid: number | string, id: number | string, data: any) => {
  const response = await ApiV2.cp.marketing.options.values.delete({oid, id}, data);
  return response?.data;
};
export const getProductProperties = async (payload: any): Promise<any> => {
  const response = await ApiV2.cp.marketing.properties.get(payload);
  return response?.data;
};

export const getProductProperty = async (id: number): Promise<any> => {
  const response = await ApiV2.cp.marketing.properties.get(id);
  return response?.data;
};

export const createProductProperty = async (data: object): Promise<any> => {
  const response = await ApiV2.cp.marketing.properties.post(data);
  return response?.data;
};

export const addValueToProductProperty = async (
  id: number,
  data: object,
): Promise<any> => {
  const response = await ApiV2.cp.marketing.properties.values.post(id, data);
  return response?.data;
};

export const addPropertiesToProduct = async (
  id: number,
  data: object,
): Promise<any> => {
  const response = await ApiV2.cp.marketing.products.properties.post(id, data);
  return response?.data;
};

export const removePropertiesFromProduct = async (
  id: number,
  data: object,
): Promise<any> => {
  const response = await ApiV2.cp.marketing.products.properties.delete(
    id,
    data,
  );
  return response?.data;
};

export const getProductVariants = async (
  id: number,
  payload: any,
): Promise<any> => {
  const response = await ApiV2.cp.marketing.products.variants.get(id, payload);
  return response?.data;
};

export const createProductVariants = async (
  id: number,
  data: object,
): Promise<any> => {
  const response = await ApiV2.cp.marketing.products.variants.put(id, data);
  return response?.data;
};

export const updateProductVariant = async (
  id: number,
  mid: number,
  data: object,
): Promise<any> => {
  const response = await ApiV2.cp.marketing.products.variants.patch(
    mid ? {id, mid} : id,
    data,
  );
  return response?.data;
};

export const getProductVariant = async (id: number): Promise<any> => {
  const response = await ApiV2.cp.marketing.products.variants.get(id);
  return response?.data;
};
export const updateProductImages = async (
  id: number,
  data: object,
): Promise<any> => {
  const response = await ApiV2.cp.marketing.products.images.patch(id, data);
  return {data: response?.data?.data, meta: response?.data?.meta};
};
export const uploadProductImages = async (
  id: number,
  data: object,
): Promise<any> => {
  const response = await ApiV2.cp.marketing.products.images.post(id, data);
  return {data: response?.data?.data, meta: response?.data?.meta};
};

export const deleteProductImages = async (
  id: number,
  data: object,
): Promise<any> => {
  const response = await ApiV2.cp.marketing.products.images.delete(id, data);
  return response?.data;
};

export const uploadProductVariantImages = async (
  id: number,
  vid: number,
  data: object,
): Promise<any> => {
  const response = await ApiV2.cp.marketing.products.variants.images.post(
    {id, vid},
    data,
  );
  return response?.data;
};

export const deleteProductVariantImages = async (
  id: number,
  vid: number,
  data: object,
): Promise<any> => {
  const response = await ApiV2.cp.marketing.products.variants.images.delete(
    {id, vid},
    data,
  );
  return response?.data;
};

export const updateProductVariantImages = async (
  id: number,
  vid: number,
  data: object,
) => {
  const response = await ApiV2.cp.marketing.products.variants.images.patch(
    {id, vid},
    data,
  );
  return response?.data;
};

export const getProductGroups = async (payload: any): Promise<any> => {
  const response = await ApiV2.cp.marketing.groups.get(payload);
  return response?.data;
};
export const deleteProductGroup = async (id: number): Promise<any> => {
  const response = await ApiV2.cp.marketing.groups.delete(id);
  return response?.data;
};

export const getProductGroup = async (id: number): Promise<any> => {
  const response = await ApiV2.cp.marketing.groups.get(id);
  return response?.data;
};

export const createProductGroup = async (data: object): Promise<any> => {
  const response = await ApiV2.cp.marketing.groups.post(data);
  return response?.data;
};

export const updateProductGroup = async (
  id: number,
  data: object,
): Promise<any> => {
  const response = await ApiV2.cp.marketing.groups.patch(id, data);
  return response?.data;
};

export const uploadProductGroupImages = async (
  id: number,
  data: object,
): Promise<any> => {
  const response = await ApiV2.cp.marketing.groups.images.post(id, data);
  return response?.data;
};

export const deleteProductGroupImages = async (
  id: number,
  data: object,
): Promise<any> => {
  const response = await ApiV2.cp.marketing.groups.images.delete(id, data);
  return response?.data;
};

export const uploadProductHeaderImages = async (
  id: number,
  data: object,
): Promise<any> => {
  const response = await ApiV2.cp.marketing.products.headerImages.post(
    id,
    data,
  );
  return response?.data;
};

export const deleteProductHeaderImages = async (
  id: number,
  data: object,
): Promise<any> => {
  const response = await ApiV2.cp.marketing.products.headerImages.delete(
    id,
    data,
  );
  return response?.data;
};

export const cloneProduct = async (id: number): Promise<any> => {
  const response = await ApiV2.cp.marketing.products.clone.post(id);
  return response?.data;
};

export const uploadProductMockups = async (
  id: number,
  data: {
    file: File;
    is_main?: number;
    screen_type_size?: string;
    related_image_id?: number;
  },
): Promise<any> => {
  const response = await ApiV2.cp.marketing.products.mockupImages.post(
    {id},
    data,
  );
  return response.data;
};
export const getSuppliers = async (data: any): Promise<any> => {
  const response = await ApiV2.cp.marketing.suppliers.get(data);
  return response.data;
};

export const createSupplier = async (data: any): Promise<any> => {
  const response = await ApiV2.cp.marketing.suppliers.post(data);
  return response.data;
};
export const getSupplier = async (id: number): Promise<any> => {
  const response = await ApiV2.cp.marketing.suppliers.get(id);
  return response.data;
};
export const updateSupplier = async (id: number, data: any): Promise<any> => {
  const response = await ApiV2.cp.marketing.suppliers.patch(id, data);
  return response.data;
};
export const deleteSupplier = async (id: number): Promise<any> => {
  const response = await ApiV2.cp.marketing.suppliers.delete(id);
  return response.data;
};
export const uploadLogoSupplier = async (
  id: number,
  data: any,
): Promise<any> => {
  const response = await ApiV2.cp.marketing.suppliers.post(id, data);
  return response.data;
};

export const getProductTypesList = async (data: any): Promise<any> => {
  const response = await ApiV2.cp.marketing.productTypes.get(data);
  return response?.data;
};

export const createProductType = async (data: any): Promise<any> => {
  const response = await ApiV2.cp.marketing.productTypes.post(data);
  return response?.data;
};
export const getProductType = async (id: number): Promise<any> => {
  const response = await ApiV2.cp.marketing.productTypes.get(id);
  return response?.data;
};
export const updateProductType = async (
  id: number,
  data: any,
): Promise<any> => {
  const response = await ApiV2.cp.marketing.productTypes.patch(id, data);
  return response?.data;
};
export const deleteProductType = async (id: number): Promise<any> => {
  const response = await ApiV2.cp.marketing.productTypes.delete(id);
  return response?.data;
};
