import styled from 'styled-components';

export const ButtonElement = styled.button`
  border: none;
  background: transparent;
  padding: 0;
  display: flex;
  align-items: center;
  gap: 10px;
  font-weight: 600;
  margin-bottom: 15px;
  cursor: pointer;

  svg {
    path {
      fill: ${props => props.theme.primaryColor};
    }
  }
`;
