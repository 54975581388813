import React, {useState} from 'react';
import PropTypes from 'prop-types';
import * as S from './styled';

const RadioButtonGroup = props => {
  const {radioBtnList, selected, name, onChangeValue, onLinkClick} = props;
  const [value, setValue] = useState(selected);

  const handleChange = event => {
    setValue(event.target.value);
    onChangeValue(event.target.value);
  };

  const onLickClickHandler = (event, item) => {
    if (value === item.value) onLinkClick(item);
    return false;
  };
  const inputs =
    radioBtnList && radioBtnList.length
      ? radioBtnList.map(item => (
          <div className="radioButtonGroup-wrap" key={item.value}>
            <label onChange={handleChange} className="radioButtonGroup-label">
              <input
                type="radio"
                className="radioButtonGroup-input"
                name={name}
                value={item.value}
                checked={item?.value === value}
              />
              <span className="radioButtonGroup-rect" />
              <span className="radioButtonGroup-name">{item.label}</span>
            </label>
            {item.link && (
              <span
                className="radioButtonGroup-link"
                onClick={event => onLickClickHandler(event, item)}>
                {item.link.label}
              </span>
            )}
          </div>
        ))
      : null;

  return <S.Root className="radioButtonGroup">{inputs}</S.Root>;
};

RadioButtonGroup.propTypes = {
  radioBtnList: PropTypes.array.isRequired,
  selected: PropTypes.string,
  name: PropTypes.string,
  onChangeValue: PropTypes.func,
  onLinkClick: PropTypes.func,
};

export default RadioButtonGroup;
