import styled from 'styled-components';

export const Root = styled.div`
  &-wrap {
    display: flex;
    align-items: center;
    position: relative;
    margin-bottom: 5px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &-label{
    margin: 0;
    cursor: pointer;
  }

  &-input {
    opacity: 0;
  }

  &-input[type="radio"]:checked + .radioButtonGroup-rect {
    &:before {
      display: block;
    }
  }

  &-rect {
    position: absolute;
    top: calc(50% - 7px);
    left: 0;
    width: 14px;
    height: 14px;
    border: 1px solid ${props => props.theme.borderColor};
    border-radius: 50%;
    background-color: ${props => props.theme.whiteColor};

    &:before {
      position: absolute;
      content: '';
      top: 1px;
      left: 1px;
      right: 1px;
      bottom: 1px;
      background-color: ${props => props.theme.primaryColor};
      display: none;
      border-radius: 50%;
    }
  }

  &-name {
    color: ${props => props.theme.textInputColor};
    font-size: 14px;
    padding-left: 10px;
    padding-right: 5px;
  }

  &-link{
    color: ${props => props.theme.primaryColor};
    font-size: 14px;
    text-decoration: underline;
    cursor: pointer;
  }
`
