import ApiV2 from '../ApiV2';

export const getPrintingFolders = async (data: any): Promise<any> => {
  const response = await ApiV2.cp.printing.folders.get(data);
  return response.data;
};
export const getPrintingProfiles = async (data: any): Promise<any> => {
  const response = await ApiV2.cp.printing.profiles.get(data);
  return response.data;
};
export const createPrintingProfile = async (data: any): Promise<any> => {
  const response = await ApiV2.cp.printing.profiles.post(data);
  return response.data;
};
export const getPrintingProfile = async (id: number | string): Promise<any> => {
  const response = await ApiV2.cp.printing.profiles.get(id);
  return response.data;
};
export const deletePrintingProfile = async (
  id: number | string,
): Promise<any> => {
  const response = await ApiV2.cp.printing.profiles.delete(id);
  return response.data;
};
export const updatePrintingProfile = async (
  id: number | string,
  data: any,
): Promise<any> => {
  const response = await ApiV2.cp.printing.profiles.patch(id, data);
  return response.data;
};
export const clonePrintingProfile = async (
  id: number | string,
  data: any,
): Promise<any> => {
  const response = await ApiV2.cp.printing.profiles.clone.post(id, data);
  return response.data;
};

export const getPrintingProfileSettings = async (
  id: number | string,
): Promise<any> => {
  const response = await ApiV2.cp.printing.profiles.settings.get(id);
  return response.data;
};
export const updatePrintingProfileSettings = async (
  id: number | string,
  data: any,
): Promise<any> => {
  const response = await ApiV2.cp.printing.profiles.settings.put(id, data);
  return response.data;
};

export const createPrintingProfileSettings = async (
  id: number | string,
  data: any,
): Promise<any> => {
  const response = await ApiV2.cp.printing.profiles.settings.post(id, data);
  return response.data;
};

export const getPrintingProcessors = async (data: any): Promise<any> => {
  const response = await ApiV2.cp.printing.processors.get(data);
  return response.data;
};
