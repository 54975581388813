import React from 'react';
import PropTypes from 'prop-types';
import emptyImageSrc from '../../../assets/images/emptyImage.png';
import * as S from './styled';

const EditImageComponent = ({
  imageSrc,
  onEditClick,
  styles = {},
  imageStyle = {},
}) => {
  const getImage = src => (
    <S.EditedImage
      className="edited-image"
      style={imageStyle}
      src={src}
      alt="edited-image"
    />
  );
  const emptyImage = getImage(emptyImageSrc);

  const getImageComponent = imageSrc => {
    if (
      !imageSrc ||
      imageSrc.includes('base64,null') ||
      imageSrc.includes('base64,false') ||
      imageSrc.includes('base64,undefined')
    ) {
      return emptyImage;
    }
    if (imageSrc.includes('base64,')) {
      return getImage(imageSrc);
    }
    if (Array.isArray(imageSrc)) {
      return imageSrc.map(item => getImage(item));
    }
    return getImage(imageSrc);
  };
  const imageComponent = getImageComponent(imageSrc);

  return (
    <S.EditImageContainer style={styles}>
      {imageComponent}
      <div className="icon-wrapper" onClick={onEditClick}>
        <svg
          id="Edit_button"
          xmlns="http://www.w3.org/2000/svg"
          width="17.664"
          height="17.646"
          viewBox="0 0 17.664 17.646">
          <path
            id="Path_321"
            d="M19.016,4.869,6.469,17.416a.68.68,0,0,1-.365.19l-2.414.563a1.4,1.4,0,0,1-1.68-1.681l.56-2.406a.681.681,0,0,1,.19-.371L15.31,1.162a2.168,2.168,0,0,1,3,0l.71.71h0A2.121,2.121,0,0,1,19.016,4.869ZM4.753,15.172,15.719,4.207l-.765-.766L3.988,14.407Zm.7,1.18L3.818,14.718,3.334,16.8ZM16.737,5.225l-.778-.778L4.993,15.412l.778.778Zm1.318-2.391-.71-.71a.776.776,0,0,0-1.073,0L15.194,3.2l1.784,1.784,1.077-1.078A.76.76,0,0,0,18.055,2.834Z"
            transform="translate(-1.973 -0.562)"
            fill="#e1007f"
          />
        </svg>
      </div>
    </S.EditImageContainer>
  );
};

EditImageComponent.propTypes = {
  onEditClick: PropTypes.func,
  styles: PropTypes.object,
};

export default EditImageComponent;
